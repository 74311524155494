import { Modal } from "@material-ui/core";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { firestore } from "../../firebase";
import { validateEmail } from "../../utils";
import ApiUrl from "../../utils/api";
import Config from "../../utils/config";
import './styles.scss';

const Home = ({ user, logout }) => {
    const isAdmin = user.role == 'admin';
    const [openRegister, setOpenRegister] = useState(false);
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [roleValue, setRoleValue] = useState(Config.ROLE_STAFF);
    const onRegister = async () => {
        if (!emailValue || !validateEmail(emailValue)) {
            alert('Email invalid!');
            return;
        }
        if (!roleValue) {
            alert('Role invalid!');
            return;
        }
        console.log("emailValue", emailValue, 'roleValue', roleValue);
        let result = await firestore.collection('Users').add({
            email: emailValue,
            role: roleValue,
            status: Config.STATUS_USING,
            name: nameValue
        });
        console.log("result", result);
    }

    return (
        <section className="home">
            <Modal
                className='my-modal'
                open={openRegister}
                onClose={() => setOpenRegister(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                >
                <div className='my-modal-content'>
                    <div className='my-modal-content-view'>
                        <div className='title'>Register</div>
                        <div className='item'>
                            <label>Name</label>
                            <input onChange={(e) => setNameValue(e.target.value)} />
                        </div>
                        <div className='item'>
                            <label>Email</label>
                            <input onChange={(e) => setEmailValue(e.target.value)} />
                        </div>
                        <div className='item'>
                            <label>Role</label>
                            <select onChange={(e) => setRoleValue(e.target.value)}>
                                <option value={Config.ROLE_STAFF}>Staff</option>
                                <option value={Config.ROLE_ADMIN}>Admin</option>
                            </select>
                        </div>
                        <div className='item'>
                            <Button variant='primary' color='primary' onClick={onRegister}>Register</Button>
                            <Button variant='secondary' color='secondary' onClick={() => setOpenRegister(false)}>Close</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <nav>
                <h1>ABC Secure</h1>
                <div className='user-info'>
                    <div className='user-name'>{user.name}</div>
                    <div className='user-email'>{user.email}</div>
                    {isAdmin ? <Button variant='primary' style={{ width: '200px' }} onClick={() => setOpenRegister(true)}>Thêm thành viên</Button> : null}
                    <Button variant='secondary' style={{ width: '200px' }} onClick={logout}>Logout</Button>
                </div>
            </nav>
            <div className='container'>
                {user.permissions.website && (
                    <GetPassItem user={user} type='website' title='Website' url='/abc-member-access' />
                )}
                {/* <GetPassItem user={user} type='cms' title='CMS' url='https://micro-enigma-235001.appspot.com' /> */}
                {user.permissions.wiki && (
                    <GetPassItem user={user} type='wiki' title='Wiki' url='https://wiki.abc-elearning.org/wp-admin' />
                )}
                {user.permissions.hero && (
                    <GetPassItem user={user} type='hero' title='Hero' url='https://abc-elearning.org/dashboard' />
                )}
                {Object.keys(user.nas).length > 0 && (
                    <GetPassItem user={user} type='nas' title='NAS' url='https://abcelearning.tw5.quickconnect.to/?launchApp=SYNO.SDS.Drive.Application' />
                )}
                <OtherLink
                    title='Quản lý công việc'
                    url='https://www.dropbox.com/scl/fi/eduy4mt001zmus0lago7l/Qu%E1%BA%A3n-l%C3%BD-c%C3%B4ng-vi%E1%BB%87c.gsheet?dl=0&rlkey=piffb9vg8pe0qtkmevrtvuous#gid=0' />
            </div>
        </section>
    );
};

const webLinks = [
    {
        name: "Passemall",
        url: "https://passemall.com"
    },
    {
        name: "ASVAB",
        url: "https://asvab-prep.com"
    },
    {
        name: "CDL",
        url: "https://cdl-prep.com"
    },
    {
        name: "DMV",
        url: "https://dmv-practicetests.com"
    },
    {
        name: "CNA",
        url: "https://cna-prep.com"
    },
    {
        name: "TEAS",
        url: "https://teas-prep.com"
    },
    {
        name: "AWS",
        url: "https://aws-prep.com"
    },
    {
        name: "Driving Theory",
        url: "https://drivingtheory-tests.com"
    },
    {
        name: "GED",
        url: "https://ged-testprep.com"
    },
    {
        name: "PMP",
        url: "https://pmp-testprep.com"
    },
    {
        name: "PTCB",
        url: "https://ptceprep.com"
    },
    {
        name: "REAL ESTATE",
        url: "https://realestate-prep.com"
    },
    {
        name: "Servsafe",
        url: "https://servsafe-prep.com"
    },
];

const GetPassItem = ({ user, type, title, url }) => {
    const [password, setPassword] = useState('');
    const [myUrl, setMyUrl] = useState(webLinks[0].url);
    const [loading, setLoading] = useState(false);

    return (
        <div className='service'>
            <h2>{title}</h2>
            <div className='item'>
                <Button variant='primary' style={{ width: '200px' }} onClick={() => {
                    setLoading(true);
                    getPassword(user, type, myUrl, password => {
                        setPassword(password);
                        setLoading(false);
                    });
                }}>Truy cập</Button>
                {type == 'website' && (
                    <select onChange={e => {
                        setMyUrl(e.target.value);
                        setPassword('');
                    }}>
                        {webLinks.map((item, index) => {
                            return (<option key={"web_"+ index} value={item.url}>{item.name}</option>);
                        })}
                    </select>
                )}
            </div>
            {loading && <div>Loading...</div>}
            {password && (
                <div className='service-new-password'>
                    <div className='new-password'>
                        {password}
                    </div>
                    <Button variant='info' onClick={() => {
                        if (type !== 'nas') {
                            navigator.clipboard.writeText(user.email)
                        } else {
                            navigator.clipboard.writeText(user.nas.username);
                        }
                    }}>
                        Copy Email
                    </Button>
                    <Button variant='info' onClick={() => {navigator.clipboard.writeText(password)}}>
                        Copy Password
                    </Button>
                    <Button href={type == 'website' ? myUrl + url : url} target='_blank' variant='primary'>
                        Open Website
                    </Button>
                </div>
            )}
        </div>
    );
}

const OtherLink = ({title, url}) => {
    return (
        <div className='service'>
            <h2>{title}</h2>
            <div className='item'>
                <a class='btn btn-primary' style={{ width: '200px' }} target='_blank' href={url}>Truy cập</a>
            </div>
        </div>
    )
}

const getPassword = (user, type, gotoUrl, callback) => {
    if(type == 'dashboard') {
        axios.post(ApiUrl.GET_PASSWORD_DASHBOARD, JSON.stringify({
            email: user.email,
            name: user.name,
            role: user.role == 'admin' ? 1 : 0
        }), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callback(response.data);
        }).catch((err) => {
            alert(err.message);
        });
    } else if (type == 'website') {
        axios.post(gotoUrl + '/wp-json/passemall/v1/reset-user-pwd', JSON.stringify({...user, role: user.permissions[type]}), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callback(response.data);
        }).catch((err) => {
            alert(err.message);
        });
    } else if (type == 'wiki') {
        axios.post(ApiUrl.GET_PASSWORD_WIKI, JSON.stringify({...user, role: user.permissions[type]}), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callback(response.data);
        }).catch((err) => {
            alert(err.message);
        });
    } else if (type == 'cms'){
        axios.post(ApiUrl.GET_PASSWORD_CMS, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callback(response.data);
        }).catch((err) => {
            alert(err.message);
        });
    } else if (type == 'hero') {
        axios.post(ApiUrl.GET_PASSWORD_HERO, JSON.stringify({
            email: user.email,
            firstName: user.name,
            lastName: "",
            role: user.role == 'admin' ? 1 : 0
        }), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response.data.response.password)
            callback(response.data.response.password);
        }).catch((err) => {
            alert(err.message);
        });
    } else if (type == 'nas') {
        callback(user.nas.password);
    }
}

export default Home;
