import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './components/Home';
import Login from './components/Login';
import { auth } from './firebase';
import DataRepositories from './repositories';

const App = () => {
    const [checkingLoggedIn, setCheckingLoggedIn] = useState(true);
    const [user, setUser] = useState();

    useEffect(() => {
        const savedEmail = window.localStorage.getItem('emailForSignIn');
        if (auth.isSignInWithEmailLink(window.location.href) && !!savedEmail) {
            auth.signInWithEmailLink(savedEmail, window.location.href)
            .then((data) => {})
            .catch((error) => {});
        }
        auth.onAuthStateChanged((user) => {
            setCheckingLoggedIn(false);
            if (user) {
                new DataRepositories().getUserInfo(user.email).then((userInfo) => {
                    setUser(userInfo);
                });
            }
        });
    }, []);

    const logout = () => {
        auth.signOut();
        window.location.reload();
    }

    if (checkingLoggedIn) {
        return (
            <div className="app-container">
                <div>Loading...</div>
            </div>
        );
    }

    return (
        <div>
            {user ? (
                <Home user={user} logout={logout} />
            ) : (
                <Login />
            )}
        </div>
    );
};

export default App;
