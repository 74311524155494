import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { auth, firestore } from '../../firebase';
import './styles.scss';

const Login = () => {
    const [email, setEmail] = useState('');
    const [showCheckEmailMessage, setShowCheckEmailMessage] = useState(false);

    const handleLogin = async(event) => {
        event.preventDefault();
        if (email !== '') {
            setShowCheckEmailMessage(false);
            const usersSnap = await firestore.collection('Users').where('email', '==', email).get();
            console.log(usersSnap.size);
            if (usersSnap.size > 0) {
                await auth.sendSignInLinkToEmail(email, {
                    url: 'https://secure.abc-elearning.org',
                    // url: 'http://localhost:3000',
                    handleCodeInApp: true,
                });
                setShowCheckEmailMessage(true);
                window.localStorage.setItem('emailForSignIn', email);
            } else {
                alert('Không tồn tại email này trong hệ thống');
            }
        }

        return false;
    }

    return (
        <section className='login'>
            <form className='formContainer' onSubmit={e => handleLogin(e)}>
                <h1>Đăng nhập</h1>
                <label>Email</label>
                <input
                    type='email'
                    autoFocus
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {showCheckEmailMessage && (
                    <p className='message'>
                        Vui lòng kiểm tra hòm thư để lấy link đăng nhập. Click <a href="https://lax-sd012.hawkhost.com:2096/" target="_blank">vào đây</a> để vào hòm thư.
                    </p>
                )}
                <div className='btnContainer'>
                    <Button type='submit' variant='danger'>Đăng nhập</Button>
                </div>
            </form>
        </section>
    )
}
export default Login;