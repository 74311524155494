import Config from "../utils/config";

export default class UserInfo {
    name = '';
    email = '';
    role = '';
    status = '';
    permissions = {};
    nas = {};

    constructor({ name, email, role, status, permissions, nas }) {
        this.name = name || '';
        this.email = email || '';
        this.role = role || Config.ROLE_STAFF;
        this.status = status || Config.STATUS_USING;
        this.permissions = permissions || {};
        this.nas = nas || {};
    }
}