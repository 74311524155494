import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var firebaseConfig = {
  apiKey: 'AIzaSyC7AK9cNmr_cPrhHQEtghD1Qq1D_hAIV5E',
  authDomain: 'abcmanagement-e0299.firebaseapp.com',
  projectId: 'abcmanagement-e0299',
  storageBucket: 'abcmanagement-e0299.appspot.com',
  messagingSenderId: '1060824719356',
  appId: '1:1060824719356:web:b21fce6db842dc17bd9c79'
};
firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();

export default firebase;